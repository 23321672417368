import useTranslation from "next-translate/useTranslation"
import { types } from "react-bricks/frontend"

import { TypographyOld } from "@app/shared/components/TypographyOld"
import theme from "@app/shared/design-lib/style-theme/theme.css"
import { BlogCategoryType } from "@app/shared/types/website-pages"
import { normalizeReactBricksPagesList } from "@app/shared/utils/functions/normalizeReactBricksPagesList"

import {
  image,
  imgHeight,
  layout,
  maxHeight,
  recommendedArticle,
  recommendedBg,
  recommendedContainer,
  row,
  span,
  text,
  typography,
} from "./style.css"

interface Props {
  greyCard?: boolean
  lightBlue?: boolean
  recentArticles: Array<types.PageFromList>
}

export const RecommendedArticles: React.FC<Props> = ({
  greyCard = false,
  lightBlue = false,
  recentArticles,
}) => {
  const { lang, t } = useTranslation()
  const articles = normalizeReactBricksPagesList(lang as Locale, recentArticles)
  const categories = t<BlogCategoryType>("blog:categories", {}, { returnObjects: true })

  return (
    <div className={recommendedBg({ lightBlue })}>
      <div className={recommendedContainer}>
        <div className={layout}>
          {articles.map((article, index) => (
            <article key={index} className={recommendedArticle({ greyCard })}>
              <a aria-label={article.title} href={article.link}>
                <div className={imgHeight}>
                  <img alt={article.imageAltText} className={image} src={article.imageSrc} />
                </div>
                <div className={typography}>
                  <div className={maxHeight}>
                    <TypographyOld variant="bodyS">
                      {categories[article.category as keyof BlogCategoryType] +
                        " · " +
                        article.publicationDate}
                    </TypographyOld>
                    <TypographyOld className={text({ isBody: false })} variant="h2">
                      {article.title}
                    </TypographyOld>
                    <TypographyOld className={text({ isBody: true })} variant="bodyM">
                      {article.articlePreview + " "}
                    </TypographyOld>
                  </div>
                  <div className={row}>
                    <TypographyOld variant="bodyS">{article.continueReadingCtaText}</TypographyOld>
                    <span className={span}>
                      <svg fill="none" height="10" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path
                          clipRule="evenodd"
                          d="M5.41421 0.292847L13.1213 7.99995L5.41421 15.7071L4 14.2928L10.2921 7.99995L4 1.70706L5.41421 0.292847Z"
                          fill={theme.colors.greyscale600}
                          fillRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </a>
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}
