import dayjs from "dayjs"
import "dayjs/locale/es"
import "dayjs/locale/it"
import "dayjs/locale/pt"
import { types } from "react-bricks/frontend"

import { ArticleCardProps } from "@app/shared/types/article-card"
import { PageKind } from "@app/shared/types/pages"
import * as casavoPages from "@shared/utils/pages"

export const normalizeReactBricksPagesList = (
  locale: Locale = "en",
  articles: Array<types.PageFromList>
): ArticleCardProps[] => {
  let continueReadingCtaText = "Continue reading"

  switch (locale) {
    case "it":
      continueReadingCtaText = "Continua a leggere"
      break
    case "es":
      continueReadingCtaText = "Sigue leyendo"
      break
    case "en":
      continueReadingCtaText = "Continue reading"
      break
    case "fr":
      continueReadingCtaText = "Lire la suite"
      break
    default:
      continueReadingCtaText = "Continue reading"
      break
  }

  return articles
    ? articles.map((article) => ({
        articlePreview: article.meta?.description || "",
        category: article.tags[0],
        continueReadingCtaText,
        headingLevel: 2,
        imageAltText: article.name,
        imageSrc: article?.meta?.featuredImage || "",
        link: `/${locale}/${casavoPages.create(locale)(PageKind.blog)}/${article.slug}/`,
        publicationDate:
          dayjs(article.publishedAt || article.createdAt)
            .locale(locale)
            .format("DD MMMM") || "",
        title: article.name,
      }))
    : []
}
