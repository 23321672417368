import useTranslation from "next-translate/useTranslation"
import React from "react"

import { TypographyOld } from "@app/shared/components/TypographyOld"
import theme from "@app/shared/design-lib/style-theme/theme.css"
import { BlogCategoryType } from "@app/shared/types/website-pages"

import { ArticleCardProps } from "../types/article-card"

import {
  articleCard,
  background,
  bodyM,
  figure,
  h2,
  height,
  image,
  inner,
  span,
  textWrapper,
} from "./ArticleCard.css"

export enum ArticleDimension {
  Small,
  Medium,
  Large,
}

function isLarge(dimension: ArticleDimension, width: number | undefined) {
  return dimension === ArticleDimension.Large && width !== undefined && width > 568
}

export const ArticleCard: React.FC<{ dimension: ArticleDimension; props: ArticleCardProps }> = ({
  dimension,
  props,
}) => {
  const { t } = useTranslation()
  const categories = t<BlogCategoryType>("blog:categories", {}, { returnObjects: true })

  return (
    <article className={articleCard({ isLarge: isLarge(dimension, undefined) })}>
      {isLarge(dimension, undefined) ? (
        <a aria-label={props.title} href={props.link}>
          <div className={background} style={{ backgroundImage: `url(${props.imageSrc})` }}>
            <div className={inner}>
              <div>
                <TypographyOld variant="bodyS">
                  {categories[props.category as keyof BlogCategoryType] + " · " + props.publicationDate}
                </TypographyOld>
                <TypographyOld className={h2} component="h2" variant="h3">
                  {props.title}
                </TypographyOld>
                <TypographyOld className={bodyM} variant="bodyM">
                  {props.articlePreview + " "}
                </TypographyOld>
              </div>
              <div className={textWrapper}>
                <TypographyOld variant="bodyS">{props.continueReadingCtaText}</TypographyOld>
                <span className={span}>
                  <svg fill="none" height="10" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path
                      clipRule="evenodd"
                      d="M5.41421 0.292847L13.1213 7.99995L5.41421 15.7071L4 14.2928L10.2921 7.99995L4 1.70706L5.41421 0.292847Z"
                      fill={theme.colors.greyscale600}
                      fillRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </a>
      ) : (
        <a aria-label={props.title} href={props.link}>
          <figure className={figure}>
            <img alt={props.imageAltText} className={image} src={props.imageSrc} />
          </figure>
          <div className={height}>
            <div style={{ height: "100%" }}>
              <TypographyOld variant="bodyS">
                {categories[props.category as keyof BlogCategoryType] + " · " + props.publicationDate}
              </TypographyOld>
              <TypographyOld className={h2} component="h2" variant="h3">
                {props.title}
              </TypographyOld>
              <TypographyOld className={bodyM} variant="bodyM">
                {props.articlePreview + " "}
              </TypographyOld>
            </div>
            <div className={textWrapper}>
              <TypographyOld variant="bodyS">{props.continueReadingCtaText}</TypographyOld>
              <span className={span}>
                <svg fill="none" height="10" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path
                    clipRule="evenodd"
                    d="M5.41421 0.292847L13.1213 7.99995L5.41421 15.7071L4 14.2928L10.2921 7.99995L4 1.70706L5.41421 0.292847Z"
                    fill={theme.colors.greyscale600}
                    fillRule="evenodd"
                  />
                </svg>
              </span>
            </div>
          </div>
        </a>
      )}
    </article>
  )
}
export {}
